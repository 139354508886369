<template>
  <div>
    <el-form ref="form" :model="form" label-width="120px" style="display: flex;">
      <div class="el-lt" style="width:85%;">
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item :class="$i18n.locale" label="品牌">
              <el-select
                v-model="form.brandIdList"
                clearable
                filterable
                multiple
                collapse-tags
                :placeholder="$t('page.selectPlaceholder')"
                style="width:100%;"
              >
                <el-option
                  v-for="item in brandOptions"
                  :key="item.id"
                  :label="item.brandName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="平台/站点" :class="$i18n.locale">
              <el-cascader
                v-model="form.platformSite"
                clearable
                filterable
                collapse-tags
                :options="alldatas"
                style="width:100%;"
                :props=" { value: 'dropDownId', label: 'dropDownName', children: 'dropDownList', multiple:true}"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="操作人" :class="$i18n.locale">
              <el-select v-model="form.operateId" clearable filterable style="width:100%;">
                <el-option v-for="item in useroptions" :key="item.id" :label="item.username" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="操作时间" :class="$i18n.locale">
              <el-date-picker
                v-model="form.value1"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </el-form-item>
          </el-col>
        </el-row>

      </div>
      <el-row class="el-rt" style="width:25%;">
        <el-col class="row-center">
          <el-button type="primary" :loading="Loading" @click="queryClickSearch">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
        </el-col>
      </el-row>
    </el-form>

    <el-row :span="24" class="mb-3">
      <el-col :span="12">
        <el-button v-permission="'add'" type="primary" @click="handleAdd('add')">新增</el-button>
        <el-button v-permission="'edit'" type="primary" @click="handleAdd('edit')">修改</el-button>
        <el-button v-permission="'batchImport'" class="mr-3" type="primary" @click="importDialogVisible=true">批量导入</el-button>
        <el-button v-permission="'deleteA'" class="mr-3" type="danger" @click="handleDelete()">删除</el-button>
      </el-col>
      <el-col :span="12" style="text-align: right;">
        <el-button v-permission="'exportA'" class="mr-3" type="primary" @click="exportDetail">导出</el-button>
      </el-col>
    </el-row>

    <el-table
      ref="multipleTable"
      v-loading="Loading"
      class="mb-3 mt-3"
      :data="tableDatas"
      max-height="550px"
      :header-cell-style="{background:'#fafafa'}"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" />
      <el-table-column
        :label="$t('page.No')"
        type="index"
        width="80"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>

      <el-table-column
        prop="brandName"
        label="品牌"
        align="center"
        min-width="100"
      />
      <el-table-column
        align="left"
        prop="platformName"
        label="平台"
        min-width="100"
      />
      <el-table-column
        prop="siteName"
        label="站点"
        align="center"
        min-width="80"
      />
      <el-table-column
        prop="mpsDays"
        label="MPS天数"
        align="center"
        min-width="150"
      />
      <el-table-column
        prop="modifyByName"
        label="操作人"
        align="center"
        min-width="100"
      />
      <el-table-column
        prop="modifyTime"
        label="操作时间"
        align="center"
      />

    </el-table>

    <Paging :pager="pager" end @pagination="pagerUpdate" />

    <!-- 新增修改弹窗 -->
    <el-dialog title="MPS天数" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false" center>
      <el-form ref="addForm" :model="addForm" label-width="100px" :rules="rules">
        <el-form-item label="品牌" prop="brand">
          <el-select
            v-model="addForm.brand"
            clearable
            filterable
            :placeholder="$t('page.selectPlaceholder')"
            style="width:90%;"
            value-key="id"
          >
            <el-option
              v-for="item in brandlimitedOptions"
              :key="item.id"
              :label="item.brandName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="平台/站点" prop="platformSite">
          <el-cascader
            v-model="addForm.platformSite"
            clearable
            filterable
            :options="limitedAlldatas"
            style="width:90%;"
            :props=" { value: 'value', label: 'platformName', children: 'siteList'}"
          />
        </el-form-item>
        <el-form-item label="MPS天数" prop="mpsDays">
          <div>
            <el-input-number v-model="addForm.mpsDays" controls-position="right" placeholder="请输入正整数" :precision="0" :min="1" :max="999999" style="width:80%;" />天
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible=false">取 消</el-button>
        <el-button type="primary" :loading="submitLoading" @click="dialogVisibleSure">提 交</el-button>
      </span>
    </el-dialog>

    <!-- 批量导入 -->
    <BatchImport v-model="importDialogVisible" @refresh="queryClick" />
  </div>
</template>

<script>
import Paging from '@/components/Pagination'
import { handleDownload, handleMonitorParams } from '@/utils'
import BatchImport from './components/BatchImport'
import { usersList, LimitedqueryBrandList, getplatformSite, getlimitedplatformSite, getMpsDayApi, mpsExport, InsertMps, ModifyMps, DeleteMps } from '@/api/scm-api'
import { queryBrandList } from '@/api/listSelection'
import { trackSiteSearch, handleBreadTitle, trackDialogEvent } from '@/utils/monitor'
import { merge } from 'lodash'

export default {
  components: { Paging, BatchImport },

  data() {
    return {
      multipleSelectionTable: [],
      form: {
        value1: [],
        style: '',
        vendorId: '',
        platformSite: ''
      },
      pager: {
        current: 1,
        size: 10,
        pages: 1,
        total: 0
      },
      brandOptions: [],
      brandlimitedOptions: [],
      useroptions: [],
      alldatas: [],
      limitedAlldatas: [],
      tableDatas: [],
      Loading: false,
      dialogVisible: false, // 新增修改弹窗
      importDialogVisible: false,
      addForm: {
        brand: '',
        mpsDays: '',
        platformSite: ''
      },
      // 新增修改
      rules: {
        platformSite: { required: true, message: this.$t('page.selectPlaceholder'), trigger: 'blur' },
        brand: { required: true, message: this.$t('page.selectPlaceholder'), trigger: 'blur' },
        mpsDays: { required: true, message: this.$t('page.inputPlaceholder'), trigger: 'blur' }
      },
      submitLoading: false,
      uploadDialogVisible: false // 组件的批量导入
    }
  },
  computed: {
    mpsDaysParams() {
      const [operateTimeStart, operateTimeEnd] = this.form.value1 || []
      const { brandIdList, operateId, platformSite } = this.form
      const operateIdList = operateId ? [operateId] : []
      const arr = []
      if (Array.isArray(platformSite)) {
        for (let i = 0; i < platformSite.length; i++) {
          const platformId = platformSite[i][0]
          const siteId = platformSite[i][1]
          arr.push({ platformId: platformId, siteId: siteId })
        }
      }
      // 平台站点id
      return Object.assign({}, this.pager, { brandIdList, operateIdList, operateTimeStart, operateTimeEnd }, { platformSiteList: arr })
    },
    addMpsParams() {
      const { platformSite, brand } = this.addForm
      const temp1 = platformSite[0].split('&&')
      const platformId = parseInt(temp1[0])
      const platformName = temp1[1]
      const temp2 = platformSite[1].split('&&')
      const siteId = parseInt(temp2[0])
      const siteName = temp2[1]
      const brandName = this.brandOptions.find(item => item.id === brand).brandName
      return Object.assign({}, { platformId: platformId, platformName: platformName, siteId: siteId, siteName: siteName, brandId: brand, brandName: brandName })
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
         this.$refs.addForm?.clearValidate()
        this.$refs.addForm?.resetFields()
      }
    }
  },
  created() {

  },
  mounted() {
    this._queryBrandList()
    this._limitedqueryBrandList()
    this._usersList()
    this._listPlatformAndSite()
    this._limitedlistPlatformAndSite()
    this.queryClick(1)
  },
  methods: {
    queryClickSearch() {
      this.queryClick(1)

      trackSiteSearch(
        JSON.stringify(handleMonitorParams(merge(this.mpsDaysParams, this.pager))),
        handleBreadTitle(this.$route),
        this.pager.size
      )
    },
    pagerUpdate(val) {
      this.pager = val
      this.queryClick()
    },
    resetClick() {
      this.form = this.$options.data.call(this).form
      this.queryClick(1)
    },
    async queryClick(flag) {
      try {
        this.Loading = true
        flag && flag === 1 ? this.pager.current = 1 : ''
        const { code, datas: { records, pager }} = await getMpsDayApi(this.mpsDaysParams, this.pager)
        if (code === 0) {
          this.pager = pager
          this.tableDatas = records
        }
      } finally {
        this.Loading = false
      }
    },
    // 导出
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    async exportDetail() {
      try {
        this.exportLoading = true
        const params = {}
        const idList = []
        if (Array.isArray(this.multipleSelection) && this.multipleSelection.length) {
          this.multipleSelection.map(item => idList.push(item.id))
          Object.assign(params, { idList: idList })
        } else {
          Object.assign(params, this.mpsDaysParams)
        }
        const data = await mpsExport(params)
        handleDownload(data, 'MPS天数配置文件.xlsx')
        this.exportLoading = false
      } finally {
        this.exportLoading = false
      }
    },

    // 新增修改
    handleAdd(type) {
      if (type === 'edit') {
        if (Array.isArray(this.multipleSelection) && this.multipleSelection.length === 1) {
          const modifyForm = this.tableDatas.find((item) => item.id === this.multipleSelection[0].id)
          const { brandId, mpsDays, platformId, platformName, siteId, siteName } = modifyForm
          this.addForm = Object.assign({ brand: Number(brandId), mpsDays: mpsDays,
            platformSite: [`${platformId}&&${platformName}`, `${siteId}&&${siteName}`] })
        } else {
          return this.$message.warning('仅可选中单笔数据进行操作')
        }
      } else {
        this.addForm = {}
      }
      this.type = type
      this.dialogVisible = true
      trackDialogEvent(handleBreadTitle(this.$route) + type)
    },
    // 验证并提交
    async dialogVisibleSure() {
      this.$refs['addForm'].validate(async valid => {
        if (valid) {
          try {
            this.submitLoading = true
            if (this.type === 'add') {
              const obj = Object.assign({}, this.addForm, this.addMpsParams)
              await InsertMps(obj)
            } else {
              const obj = Object.assign({}, this.addForm, this.addMpsParams, { id: this.multipleSelection[0].id })
              await ModifyMps(obj)
            }
            this.submitLoading = false
            this.dialogVisible = false
            // this.edit = false
            this.queryClick(0)
          } finally {
            this.submitLoading = false
          }
        }
      }
      )
    },

    // 删除
    handleDelete() {
      if (Array.isArray(this.multipleSelection) && this.multipleSelection.length) {
        this.$confirm(`你选中了${this.multipleSelection.length}条数据进行删除，该操作无法撤销，请确认！`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          // const { id } = this.multipleSelection
          const idList = this.multipleSelection.map(item => item.id)
          await DeleteMps(idList)
          this.queryClick(0)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      } else {
        this.$message({
          message: '请至少选择一条数据删除',
          type: 'warning'
        })
        return false
      }
    },
    // 下拉信息获取
    async _queryBrandList() {
      const { datas } = await queryBrandList()
      this.brandOptions = datas
    },
    async _limitedqueryBrandList() {
      const { datas } = await LimitedqueryBrandList()
      this.brandlimitedOptions = datas
    },
    async _usersList() {
      const { datas } = await usersList()
      this.useroptions = datas
    },
    // 所有平台站点下拉
    async _listPlatformAndSite() {
      const { datas } = await getplatformSite()
      this.alldatas = datas
    },
    // 带权限控制的平台站点下拉
    async _limitedlistPlatformAndSite() {
      const { datas } = await getlimitedplatformSite()
      this.limitedAlldatas = datas.map(data => ({
        ...data,
        siteList: data.siteList.map(item => ({
          platformId: item.siteId,
          platformName: item.siteName
        }))
      }))
      this.getDataTree(this.limitedAlldatas)
    },
    getDataTree(data) {
      if (!Array.isArray(data)) return
      for (let i = 0; i < data.length; i++) {
        data[i].value = `${data[i].platformId}&&${data[i].platformName}`
        this.getDataTree(data[i].siteList)
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
